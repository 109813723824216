@import "_libs/variables";
@import "_apps/header";
@import "_apps/footer";
@import "node_modules/bootstrap/scss/bootstrap";

$bootstrap-icons-font-src:
    url("../fonts/bootstrap-icons.woff2") format("woff2"),
    url("../fonts/bootstrap-icons.woff") format("woff");
@import "node_modules/bootstrap-icons/font/bootstrap-icons";

@import "_app";

h1 {
    font-family: $font-family-monospace;
}

h2 {
    font-family: $font-family-monospace;
}

.nav-link:hover {
    color: rgb(240, 111, 14);
}

.nav-link.active {
    color: rgb(240, 111, 14);
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: none;
}

.contact:hover {
    background-color: #f6f6f6;
    color: $primary;
}

// Start Search

.search-container {
    position: relative;
    width: 300px;
}

#search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid white;
    border-radius: 5px 5px 0 0;
    background-color: transparent;
    color: white;
    // font-size: 16px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#suggestion-list {
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    color: black;
    background-color: white;
    z-index: 1;
    display: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#suggestion-list li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    list-style: none;
    text-align: left;
}

#suggestion-list li:hover {
    background-color: #f0f0f0;
}
#map-canvas {
    height: 600px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}
.suggestions:hover {
    background-color: $primary;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}
.our-store{
    .gm-style-iw {
        max-height: 500px !important;
        min-height: 100px !important;
        min-width: 650px;
        .gm-style-iw-d {
            max-height: 500px !important;
            min-width: 300px;
        }
    }
    
    @media only screen and (max-width: 375px) {
        .gm-style-iw {
            max-height: 500px !important;
            min-height: 100px !important;
            min-width: 300px !important;
        }
    }
    
}

.form-control .phone {
    margin-top: 0px !important;
}

#map-all {
    height: 700px;
    width: 100%;
}

@media only screen and (max-width: 375px) {
    #map-all {
        height: 500px;
        width: 100%;
    }
}
