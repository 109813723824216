$bgimages:
  "slide" "slide.webp",
  "helthcare" "helthcare-automation.jpg";
  
  
  
  
  

.bg-img {    
    width: 100%;
    height: 100%;
}

@each $name, $file in $bgimages {
  .bg-img-#{$name} {    
    background-image: url("../img/bg/#{$file}");
    background-repeat: no-repeat;
  }
}

