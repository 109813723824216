@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Play:wght@700&family=Poppins&family=Russo+One&display=swap');

$white: #fff;
$gray-100: rgb(0, 0, 0);
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #241F23;
$gray-700: #495057;
$gray-800: #333;
$gray-900: #212529;
$black: #000;
$secondary:#212529;
$blue: #007bff;
$indigo: #6610f2;
$purple: #772953;
$pink: #e83e8c;
$red: #DF382C;
$orange: rgb(240, 111, 14);
$yellow: #EFB73E;
$green: #38B44A;
$teal: #20c997;
$cyan: #17a2b8;
$primary: rgb(240, 111, 14);
$light:#F6F6F6;
$gray: #ced4da;
// $secondary: $gray-500;
// $success: $green;
// $info: $cyan;
// $warning: $yellow;
// $danger: $red;
// $light: $gray-200;
// $dark: $purple;
// $body-color: $gray-800;
// $link-hover-color: darken($link-color, 100%);
// $link-hover-decoration: none;
// $navbar-dark-disabled-color: $white;
// $font-family-monospace: Poppins;

$font-family-poppins: Poppins;
$font-family-monospace: Play, "Liberation Mono", "Courier New", monospace;;

$font-family-base: $font-family-poppins !default;